
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class HousfyProgressCircle extends Vue {
  @Prop({ type: Number, required: true })
  percentage!: number;
  @Prop({ type: Number, required: true })
  strokeWidth!: number;
  @Prop({ type: String, required: true })
  strokeColor!: string;
}
